body {
  font-family: Helvetica, sans-serif;
  text-align: center;
  font-size: 50;

}

#slideshow {
  border: 3px solid black;
  border-radius: 15px;
  overflow: hidden;
  height: 810px;
  width: 1080px;
  margin: 0 auto;
}

.slide {
  float: left;
  height: 810px;
  width: 1080px;
}

.slide-wrapper {
  width: calc(1080px * 5);
   animation: slide 40s ease infinite; 
}

.slide:nth-child(1) {
  background-image: url('../../assets/Zeltlager.jpeg');
  background-size: 1080px 810px;
}

.slide:nth-child(2) {
  background-image: url('../../assets/Spiele-We.jpeg');
  background-size: 1080px 810px;
}

.slide:nth-child(3) {
  background-image: url('../../assets/Nikolausaktion.jpeg');
  background-size: 1080px 810px;
}

.slide:nth-child(4) {
  background-image: url('../../assets/APS.jpeg');
  background-size: 1080px 810px;
}

.slide:nth-child(5) {
  background-image: url('../../assets/Gruppenbild\ Leiter.png');
  background-size: 1080px 810px;
}

@keyframes slide {
  16% {
    margin-left: 0%;
  }
  32% {
    margin-left: calc(-1080px * 1);
  }
  48% {
    margin-left: calc(-1080px * 2);
  }
  64% {
    margin-left: calc(-1080px * 3);
  }
  80% {
    margin-left: calc(-1080px * 4);
  }
}

.button {
  width: auto;
  cursor: pointer;
}

.content {
  color: aqua;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}

@media (max-width:1199px){
  .Slide-Container{
    display: none;
  }
}